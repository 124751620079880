<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="6"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ booking.stringID }} {{ booking.activityType ? booking.activityType.typeName : '' }} - {{ booking.customerName }}
            </h1>
            <p class="log_info">
              Created by {{ booking.createdBy ? booking.createdBy.name : '' }} on {{ dateFormatWithTime(booking.createdAt) }}<br>Last updated on {{ dateFormatWithTime(booking.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="6"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="text-capitalize">
              {{ booking.status ? booking.status.replace("-", " ") : '' }}
            </h5>
          </div>
          <div class="action_button">
            <b-button
              v-b-modal.booking-all-modal-update-status
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
        <div class="user_block">
          <div class="user_avatar">
            <img :src="booking.dutyOfficer ? (booking.dutyOfficer.image ? booking.dutyOfficer.image : defaultImage) : defaultImage">
          </div>
          <div class="user_info">
            <h6>Duty Officer</h6>
            <h5>{{ booking.dutyOfficer ? booking.dutyOfficer.name : '' }}</h5>
          </div>
          <div class="action_button">
            <b-button
              v-b-modal.modal-update-duty-officer
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details booking_details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Customer Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-bookings-edit', params: { id: '618235a2a3c26a7dd2baf81a' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Customer Name
            </b-th>
            <b-td>{{ booking.customerName }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Mobile Number
            </b-th>
            <b-td>{{ booking.customerContact }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Email
            </b-th>
            <b-td>{{ booking.customerEmail }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Booking Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-bookings-edit', params: { id: '618235a2a3c26a7dd2baf81a' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Activity Type
            </b-th>
            <b-td>
              <b-badge
                :variant="`${booking.activityType ? booking.activityType.color : 'primary'}`"
                class="small-badge-button small-badge-button-activity-list"
                :style="{ '--bg-color': booking.activityType ? booking.activityType.color : '#104D9D' }"
              >
                {{ booking.activityType ? booking.activityType.typeName : '' }}
              </b-badge>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Start Date and Time
            </b-th>
            <b-td>
              <span>{{ dateFormat(booking.start) }}</span>
              <div v-if="booking.start != booking.end">
                <span>{{ timeFormatFromDateString(booking.start) }}</span> - <span>{{ timeFormatFromDateString(booking.end) }}</span>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Source of Booking
            </b-th>
            <b-td><span class="text-capitalize">{{ booking.sourceOfBooking }}</span></b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Title
            </b-th>
            <b-td>{{ booking.title ? booking.title : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Description
            </b-th>
            <b-td>{{ booking.description ? booking.description : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Remarks
            </b-th>
            <b-td>{{ booking.remarks ? booking.remarks : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Payment Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-bookings-edit', params: { id: '618235a2a3c26a7dd2baf81a' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Payment Remarks
            </b-th>
            <b-td>{{ booking.paymentRemarks }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Agent Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-bookings-edit', params: { id: '618235a2a3c26a7dd2baf81a' } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Agent Name
            </b-th>
            <b-td>{{ booking.agentName ? booking.agentName : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Agent Code
            </b-th>
            <b-td>{{ booking.agentCode ? booking.agentCode : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-row
      class="content-header v-sticky-sidebar-container"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2 top-info-right"
        cols="12"
        md="3"
      >
        <vue-sticky-sidebar
          class="sidebar"
          container-selector=".v-sticky-sidebar-container"
          inner-wrapper-selector=".sidebar__inner"
        >
          <scrollactive
            class="my-nav"
            active-class="high-opacity"
          >
            <a
              href="#scroll-item-1"
              class="scrollactive-item"
            >
              <b-card
                no-body
                class="sticky_sidebar mb-50 low-opacity"
              >
                <div class="event-info p-1">
                  <h3>1. Urn Entitled or Urn In Niche</h3>
                  <p>Due on 05/11/2021</p>
                </div>
              </b-card>
            </a>

            <a
              href="#scroll-item-2"
              class="scrollactive-item"
            >
              <b-card
                no-body
                class="sticky_sidebar mb-50 low-opacity"
              >
                <div class="event-info p-1">
                  <h3>2. Upload Required Documents</h3>
                  <p>Due on 05/11/2021</p>
                </div>
              </b-card>
            </a>

            <a
              href="#scroll-item-3"
              class="scrollactive-item"
            >
              <b-card
                no-body
                class="sticky_sidebar mb-50 low-opacity"
              >
                <div class="event-info p-1">
                  <h3>3. Assign Bone Picker</h3>
                  <p>Due on 05/11/2021</p>
                </div>
              </b-card>
            </a>
          </scrollactive>
        </vue-sticky-sidebar>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right d-md-block d-none mb-1"
        md="9"
        cols="12"
      >
        <b-card
          id="scroll-item-1"
          no-body
          class="mb-50 scroll-item-1"
        >
          <div
            v-for="index in 10"
            :key="index"
            class="event-info p-1"
          >
            <h3>Long Long Block</h3>
            <p>Due on 05/11/2021</p>
          </div>
        </b-card>

        <b-card
          id="scroll-item-2"
          no-body
          class="mb-50 scroll-item-2"
        >
          <div
            v-for="index in 15"
            :key="index"
            class="event-info p-1"
          >
            <h3>Long Long Block</h3>
            <p>Due on 05/11/2021</p>
          </div>
        </b-card>

        <b-card
          id="scroll-item-3"
          no-body
          class="mb-50 scroll-item-3"
        >
          <div
            v-for="index in 2"
            :key="index"
            class="event-info p-1"
          >
            <h3>Long Long Block</h3>
            <p>Due on 05/11/2021</p>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-update-duty-officer"
      ref="update-duty-officer-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Duty Officer"
      @ok="dutyOfficerSubmitForm"
    >
      <b-form @submit.prevent="dutyOfficerSubmitForm">
        <validation-observer
          ref="bookingDutyOfficerEditForm"
        >
          <b-form-group
            label="Duty Officer*"
            label-for="h-booking-dutyOfficer"
          >
            <validation-provider
              #default="{ errors }"
              name="Duty Officer"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-booking-dutyOfficer"
                v-model="dutyOfficer"
                label="name"
                :options="dutyOfficerOptions"
                :reduce="name => name._id"
                :clearable="false"
                placeholder="Select an option"
                @input="dutyOfficerValidation == true ? dutyOfficerValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="dutyOfficerValidation"
                class="text-danger"
              >
                {{ dutyOfficerError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="booking-all-modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="allBookingStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vueStickySidebar from 'vue-sticky-sidebar'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BBadge, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BBadge,
    BForm,
    BFormGroup,

    vSelect,
    ValidationProvider,
    ValidationObserver,

    'vue-sticky-sidebar': vueStickySidebar,
  },
  data() {
    return {
      booking: {},
      bookingID: '618235a2a3c26a7dd2baf81a',
      stringID: '',
      status: 'confirmed',
      statusValidation: false,
      statusError: 'Valid status is required',
      dutyOfficer: '',
      dutyOfficerValidation: false,
      dutyOfficerError: 'Valid duty officer is required',
      dutyOfficerOptions: [],
      bookingActivityLogs: [],
      statusDataOptions: [
        { title: 'Confirmed', code: 'confirmed' },
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Payment', code: 'pending-payment' },
        { title: 'Pending Approval', code: 'pending-approval' },
        { title: 'Waiting List', code: 'waiting-list' },
        { title: 'Cancelled', code: 'cancelled' },
      ],

      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  created() {
    this.$http.get('appointment/bookings/618235a2a3c26a7dd2baf81a/show')
      .then(response => {
        this.booking = response.data.data || {}
        this.dutyOfficerOptions = response.data.dutyOfficers || []

        this.setData()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    setData() {
      this.bookingID = this.booking._id
      this.status = this.booking.status
      this.stringID = this.booking.stringID
      this.dutyOfficer = this.booking.dutyOfficer
      this.bookingActivityLogs = this.booking.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
    },
    dutyOfficerSubmitForm() {
      this.$refs.bookingDutyOfficerEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('dutyOfficer', this.dutyOfficer)

          this.$http.patch(`appointment/bookings/${this.bookingID}/duty-officer/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.booking.dutyOfficer = response.data.data.dutyOfficer || {}
              this.booking.activityLog = response.data.data.activityLog || []
              this.setData()
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Duty Officer Updated!',
                html: `Duty Officer for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'dutyOfficer') {
                    this.dutyOfficerError = validationError.msg
                    this.dutyOfficerValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    submitForm() {
      this.$refs.allBookingStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`appointment/bookings/${this.bookingID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.booking.status = response.data.data.status || 'confirmed'
              this.booking.activityLog = response.data.data.activityLog || []
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'title-and-text') return 'Title and Text'
      if (val === 'short-answer') return 'Short Answer Field'
      if (val === 'long-answer') return 'Long Answer Field'
      if (val === 'multi-select') return 'Selection List Field (Add-Ons)'
      if (val === 'single-select') return 'Single Checkbox Field'
      return '-'
    },
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .low-opacity {
    opacity: 0.6;
  }
  .high-opacity div {
    opacity: 1 !important;
  }
</style>
